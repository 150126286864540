<template>
  <CHeader fixed with-subheader light class="weed-green">
    <StagingBanner :environment="environment" v-if="environment !== 'production'" class="staging-banner" />
    <CHeaderBrand class="mx-3 mx-lg-0 ml-lg-3" href="/">
      <img src="@/assets/img/brand/logo.png" height="48" alt="Logo"/>
    </CHeaderBrand>

    <CHeaderNav class="mr-2 ml-auto">
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import StagingBanner from '@shared/components/StagingBanner'
import TheHeaderDropdownAccnt from '@shared/components/TheHeaderDropdownAccnt'

export default {
  components: {
    StagingBanner,
    TheHeaderDropdownAccnt,
  },
  computed: {
    environment() {
      switch (location.hostname) {
        case 'portal.holisticindustries.net':
          return 'production';
        case 'dev-portal.holisticindustries.net':
          return 'staging';
        case 'localhost':
          return 'development';
        default:
          return 'unknown'; // or any other identifier for non-production
      }
    },
  },
  name: 'TheHeader',
}
</script>

<style scoped>
  .staging-banner {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
</style>