<template>
  <div class="c-app">
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>

    <b-modal id="maintenance"
            hide-header
            no-close-on-esc
            no-close-on-backdrop
            hide-footer
            centered>
      <b-row class="justify-content-center">
        <b-col cols="12">
          <p>The portal is undergoing routine maintenance. We will be back online by 6:00PM EST.</p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import TheHeader from './TheHeader'

export default {
  name: 'TheContainer',
  components: {
    TheHeader,
  },
  mounted() {
    if(!true) this.$bvModal.show('maintenance')
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
