<template>
    <b-alert :show="true" :variant="alertVariant" class="text-center">
        <strong>Be aware:</strong> You are working in the <strong>{{ environment }}</strong> environment.
        &nbsp;
        <a :href="getLink('production')" v-if="environment !== 'production'">Open in Production</a>
        <span v-if="environment === 'development'"> | </span>
        <a :href="getLink('staging')" v-if="environment === 'development'">Open in Staging</a>
    </b-alert>
</template>

<script>
export default {
    name: 'StagingBanner',
    props: {
        environment: {
            type: String,
            required: true,
        },
    },
    computed: {
        alertVariant() {
            return this.environment === 'development' ? 'info' : 'warning';
        },
    },
    methods: {
        getLink(targetEnvironment) {
            const currentPath = window.location.pathname;
            const currentHost = window.location.host;
            const targetHost = targetEnvironment === 'staging' ? 'dev-portal.holisticindustries.net' : 'portal.holisticindustries.net';
            const protocol = window.location.protocol;
            return `${protocol}//${targetHost}${currentPath}`;
        },
    },
}
</script>
