<template>
    <CDropdown inNav
               class="c-header-nav-items"
               placement="bottom-end"
               add-menu-classes="pt-0">
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img :src="require('@shared/assets/img/user.jpeg')"
                         class="c-avatar-img " />
                </div>
            </CHeaderNavLink>
        </template>
        <CDropdownItem href="/support">
            <i class="fa fa-ticket fa-2xl mr-2"></i> Support
        </CDropdownItem>
        <CDropdownItem href="/change-password">
            <i class="fa fa-ticket fa-2xl mr-2"></i> Change Password
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem @click="logout">
            <CIcon name="cil-lock-locked" /> Logout
        </CDropdownItem>
    </CDropdown>
</template>

<script>


  export default {
    name: 'TheHeaderDropdownAccnt',
    data () {
      return { 
        
      }
    },

    methods: {
      async logout() {
        try {
          this.$store.commit('reset')
          await this.$router.replace('/login')
        } catch (e) {
          this.$log.error('Error logging out', e)
        }

        window.location.href = window.location.href
      }
    },
  }
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>